import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styles from "./staticPages.module.scss"
import Collapsible from "elements/Collapsible"
//import { getSignedInUser } from "../../Auth/services/user"
import slugify from "slugify"
export const renderStaticPage = ({
  pageContent,
  type,
  website,
  module,
  formFields,
}) => {
  //const { userData } = getSignedInUser()
  switch (type.toUpperCase()) {
    case "INFORMED CONSENT":
    case "TERMS AND CONDITIONS":
      return (
        <div>
          {pageContent.map((section) => (
            <div>
              <div className={styles["staticContent"]}>
                {documentToReactComponents(JSON.parse(section.content.raw))}
              </div>
            </div>
          ))}
        </div>
      )
    case "PRIVACY POLICY":
      return (
        <div className={styles["staticContent"]}>
          {pageContent.map((section) =>
            section.isMainDescription ? (
              documentToReactComponents(JSON.parse(section.content.raw))
            ) : (
              <Collapsible title={section?.title}>
                {documentToReactComponents(JSON.parse(section.content.raw))}
              </Collapsible>
            )
          )}
        </div>
      )
    case "FREQUENTLY ASKED QUESTIONS":
    default:
      return (
        <div className={styles["staticContent"]}>
          {pageContent.map((section) => {
            let sectionItems = section.subsection?.map((subsection) => (
              <Collapsible title={subsection?.title}>
                {documentToReactComponents(
                  JSON.parse(subsection?.content?.raw)
                )}
              </Collapsible>
            ))

            return (
              <div id={slugify(section?.title)}>
                <h4>{section?.title}</h4>
                {sectionItems}
              </div>
            )
          })}
        </div>
      )
  }
}
